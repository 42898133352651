<template>
  <div>
    <div class="d-flex justify-start align-center px-5" style="height:60px;background-color:#fff;">
      <v-icon color="#d31145" @click="goback">
        mdi-arrow-left
      </v-icon>
      <span style="padding-left:15px;font-weight:700;color:#d31145">
        {{ $route.query.id ? 'Ubah Produk' : 'Tambah Produk' }}
      </span>
    </div>

    <v-divider></v-divider>

    <div style="height:calc(100vh - 130px);overflow:auto">
      
      <v-form>
        <div class="d-flex justify-center align-center py-6">
          <v-card
            flat
            class="pa-0 c-p"
            style="background: transparent;">
            <image-input v-model="avatar" type="uploadCover">
              <div slot="activator">
                <v-avatar
                  tile
                  size="120"
                  class="grey lighten-1 cursor-pointer rounded-xl">
                  <v-img
                    v-if="!avatar && addImage === ''"
                    :src="form.image_url"
                    class="mx-auto"
                    width="120"
                    height="120"
                    gradient="to top right, rgba(0,0,0,.1), rgba(0,0,0,.1)">
                    <v-icon
                      v-if="!avatar && addImage === '' && form.image_url === ''"
                      color="white"
                      style="z-index:2"
                      size="40">
                      mdi-camera-plus
                    </v-icon>
                  </v-img>

                  <div
                    class="px-5"
                    v-if="addImage && avatar.url === undefined">
                    <v-progress-linear
                      color="#d31145"
                      indeterminate
                      rounded
                      height="6"
                      class="mx-4 mt-4"
                      style="width: 80px;">
                    </v-progress-linear>

                    <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                      Mengupload Gambar . . .
                    </p>
                  </div>

                  <v-img
                    v-if="avatar && !addImage"
                    :src="form.image_url"
                    :alt="form.image_url"
                    class="ma-auto"
                    gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
                    width="120"
                    height="120">
                    <v-icon
                      color="white"
                      size="40"
                      v-if="avatar && !addImage">
                      mdi-camera-retake
                    </v-icon>
                  </v-img>
                </v-avatar>
              </div>
            </image-input>
          </v-card>

          <p class="caption mt-1 mb-1 red--text" v-show="error.image_url">
            {{ error.image_url }}
          </p>
        </div>

        <div class="d-flex justify-center align-center" style="padding: 15px 20px 0px 20px;">
          <v-row>
            <v-col cols="12">

              <div class="subtitle-2 font-weight-bold text-title">
                Nama Produk
              </div>

              <v-text-field
                v-model="form.name"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="100"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.name.length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 100 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              
              <div class="subtitle-2 font-weight-bold text-title">
                Kategori Produk
              </div>

              <v-text-field
                v-model="form.category_name"
                placeholder="Tuliskan disini"
                required
                readonly
                @click="openSelected('category')"
                color="#d31145"
                maxlength="100"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.category_name.length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 50 karakter
                  </span>  
                </div>
              </div>

            </v-col>

            <v-col cols="12">

              <div class="subtitle-2 font-weight-bold text-title">
                Kode SKU
              </div>

              <v-text-field
                v-model="form.sku"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="30"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-optional">
                  Optional
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.sku.length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 30 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12" v-if="!$route.query.id">

              <div class="subtitle-2 font-weight-bold text-title">
                Stok Awal
              </div>

              <v-currency-field
                v-model="form.stock"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="30"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-currency-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.stock.toString().length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 30 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">

              <div class="subtitle-2 font-weight-bold text-title">
                Minimum Penjualan
              </div>

              <v-currency-field
                v-model="form.sales_minimum"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="4"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-currency-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.sales_minimum.toString().length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 4 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">

              <div class="subtitle-2 font-weight-bold text-title">
                Satuan Produk
              </div>

              <v-text-field
                v-model="form.unit_title"
                placeholder="Tuliskan disini"
                required
                readonly
                @click="openSelected('satuan')"
                color="#d31145"
                maxlength="100"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.unit_title.length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 50 karakter
                  </span>  
                </div>
              </div>

            </v-col>

            <v-col cols="12">

              <div class="subtitle-2 font-weight-bold text-title">
                Harga Beli
              </div>

              <v-currency-field
                v-model="form.purchase_price"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="10"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-currency-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.purchase_price.toString().length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 10 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">

              <div class="subtitle-2 font-weight-bold text-title">
                Harga Jual
              </div>

              <v-currency-field
                v-model="form.selling_price"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="10"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-currency-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.selling_price.toString().length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 10 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">

              <div class="subtitle-2 font-weight-bold text-title">
                Tipe Produk
              </div>
              
              <v-text-field
                v-model="form.type"
                placeholder="Tuliskan disini"
                required
                readonly
                @click="openSelected('tipe')"
                color="#d31145"
                maxlength="100"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.type.length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 50 karakter
                  </span>  
                </div>
              </div>

            </v-col>

            <v-col cols="12">

              <div class="subtitle-2 font-weight-bold text-title">
                Deskripsi Produk
              </div>

              <v-text-field
                v-model="form.desc"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="100"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-optional">
                  Optional
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.desc.length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 100 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="mb-2 subtitle-2 font-weight-bold text-title">
                Diskon Produk
              </div>

              <div class="d-flex justify-start align-start">
                <div
                  @click="form.discount_type = 'nominal'"
                  :style="form.discount_type == 'nominal' ? 
                  'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 5px 0px 0px 5px;'
                  : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 5px 0px 0px 5px;'">
                  Rp
                </div>
                <div
                  @click="form.discount_type = 'percent'"
                  :style="
                  form.discount_type == 'percent' ?
                    'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 0px 5px 5px 0px;'
                    : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 0px 5px 5px 0px;'
                    ">
                  %
                </div>

                <div class="pa-1 text-capitalize pl-4" style="color: #ACACAC">
                  {{ form.discount_type == 'percent' ? 'Persen' : 'Nominal' }}
                </div>
              </div>

              <v-currency-field
                type="number"
                v-model="form.discount_value"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="10"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-currency-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">
                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">

                  <span style="font-weight:bold">
                    {{ form.discount_value.toString().length }}
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 10 karakter
                  </span> 
                </div>
              </div>
              
            </v-col>

            <v-col cols="12">
              <div class="d-flex justify-space-between align-center">
                <div class="subtitle-2 font-weight-bold text-title">
                  {{ form.is_selling == 1 ?  "Tampil" : "Tidak Tampil" }} di Kasir
                </div>
                <div>
                  <v-switch
                    color="#d31145"
                    v-model="form.is_selling"
                    :false-value="0"
                    :true-value="1">
                  </v-switch>
                </div>
              </div>

              <div class="d-flex justify-space-between align-center">
                <div class="subtitle-2 font-weight-bold text-title">
                  Produk {{ form.is_active == 1 ?  "Aktif" : "Tidak Aktif" }}
                </div>
                <div>
                  <v-switch
                    color="#d31145"
                    v-model="form.is_active"
                    :false-value="0"
                    :true-value="1">
                  </v-switch>
                </div>
              </div>
            </v-col>
            
          </v-row>
        
        </div>
      </v-form>

    </div>

    <div style="position:absolute;bottom:0;width:100%;background-color:#fff;height:60px" class="d-flex justify-center align-center px-6">
      <v-btn 
        @click="toValidation()" 
        :loading="process.run" 
        :disabled="process.run"
        rounded 
        style="flex:1"
        elevation="0"
        color="#d31145" 
        class="text-capitalize white--text mr-2 d-flex justify-space-between">
        <v-icon color="white" left>
          mdi-check-circle-outline
        </v-icon>

        <span>
          {{ $route.query.id ? 'Simpan perubahan' : 'Tambahkan data' }}
        </span>
      </v-btn>

      <v-btn
        @click="goback"
        :loading="process.run" 
        :disabled="process.run" 
        rounded 
        style="flex:.5"
        outlined
        elevation="0" 
        color="#D31145" 
        class="text-capitalize">
        <span>
          Batalkan
        </span>
      </v-btn>
    </div>

    <v-dialog v-model="dialog.selected" scrollable persistent width="350">
      <v-card class="rounded-lg">

        <v-card-title 
          class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" 
          style="
            background-color: #d31145;
            height:50px;">
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
              Pilih&nbsp;
              <span>
                {{ dialog.selection == 'category' ? 'Kategori Produk' : dialog.selection == 'satuan' ? 'Satuan' : 'Tipe Produk' }}
              </span>&nbsp;
              <span>
                ({{ dialog.selection == 'category' ? category_list.length : dialog.selection == 'satuan' ? unit_list.length :  3 }})
              </span>
          </div>

          <v-icon @click="dialog.selected = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>

        <v-divider></v-divider>
        
        <v-card-text class="px-0" style="height:300px" v-if="dialog.selection == 'category'">

          <v-card
            flat
            class="mx-auto">

            <v-list class="py-0">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in category_list"
                  :key="i"
                  @click="addData(item,i)">

                  <v-list-item-avatar>
                    <img :src="item.image_url == '' ? require('@/assets/images/icon_store.png') : item.image_url" alt="" width="30">
                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title v-text="item.name"></v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>

                    <v-icon color="#D0D0D0">
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>

        <v-card-text class="px-0" style="height:300px" v-if="dialog.selection == 'satuan'">

          <v-card
            flat
            class="mx-auto">

            <v-list class="py-0">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in unit_list"
                  :key="i"
                  @click="addData(item,i)">

                  <!-- <v-list-item-avatar>
                    <img :src="item.image_url == '' ? require('@/assets/images/icon_store.png') : item.image_url" alt="" width="30">
                  </v-list-item-avatar> -->

                  <v-list-item-content>

                    <v-list-item-title v-text="item.title"></v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>

                    <v-icon color="#D0D0D0">
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>

        <v-card-text class="px-0" style="height:300px" v-if="dialog.selection == 'tipe'">

          <v-card
            flat
            class="mx-auto">

            <v-list class="py-0">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in product_type_list"
                  :key="i"
                  @click="addData(item,i)">

                  <!-- <v-list-item-avatar>
                    <img :src="item.image_url == '' ? require('@/assets/images/icon_store.png') : item.image_url" alt="" width="30">
                  </v-list-item-avatar> -->

                  <v-list-item-content>

                    <v-list-item-title v-text="item.value"></v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>

                    <v-icon color="#D0D0D0">
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>
      
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          {{ $route.query.id ? 'Ubah Produk' : 'Tambah Produk' }}
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin {{ $route.query.id ? 'mengubah' : 'menambahkan' }} Produk &nbsp;
          <span class="font-weight-bold text-title text-capitalize"> 
            {{ form.name }} 
          </span> &nbsp;
          ?
        </div>

        <div class="d-flex justify-star align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="save()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, {{ $route.query.id ? 'Simpan Perubahan' : 'Tambahkan Data' }}
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

  </div>
</template>

<script>
import ImageInput from "@/components/ImageInput";
export default {
  data: () => ({
    unit_list: [],
    category_list: [],
    product_type_list: [
      {
        value: "Produk",
      },
      {
        value: "Bahan baku",
      },
      {
        value: "Layanan",
      }
    ],
    form: {
      id: "",
      image_url: "",
      name: "",
      category_name: "",
      category_id: 0,
      sku: "",
      stock: 0,
      sales_minimum: 1,
      unit_id: 0,
      unit_title: "",
      purchase_price: 0,
      selling_price: 0,
      type: "Produk",
      discount_type: "nominal",
      discount_value: 0,
      desc: "",
      is_selling: 1,
      is_active: 1
    },
    confirmation: false,
    process: {
      run: false
    },
    avatar: null,
    addImage: "",
    error: {
      image_url: "",
    },
    dialog: {
      selected: false,
      selection: "satuan",
      store_id: ""
    }
  }),
  components: {
    ImageInput
  },
  watch: {
    avatar: {
      handler: function() {
        this.form.image_url = this.avatar.url;
        this.addImage = this.avatar.add;
      },
      deep: true,
    },
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  created() {

  },
  mounted(){
    this.getUnit()
    this.getCategory()
    this.avatar = {
      url: "https://sodapos.com/media/2021/12/image/produk-1638864019.png",
      add: false
    }
    this.form.image_url = "https://sodapos.com/media/2021/12/image/produk-1638864019.png"

    if(this.$route.query.id){
      this.getDetail()
    }
    this.addImage = false

  },
  methods: {
    goback() {
      this.$router.push('/master/product')
    },

    async openSelected(status) {
      if(status == 'category') {
        await this.getCategory()
        this.dialog.selection = 'category'
      }else if(status == 'satuan') {
        await this.getUnit()
        this.dialog.selection = 'satuan'
      }else {
        this.dialog.selection = 'tipe'
      }

      this.dialog.selected = true
    },

    addData(item){
      console.log(item);
      if(this.dialog.selection == 'category') {
        console.log('category');
        this.form.category_id = item.id
        this.form.category_name = item.name
        this.dialog.selection = 'category'
      }else if(this.dialog.selection == 'satuan') {
        console.log('satuan');
        this.form.unit_id = item.id
        this.form.unit_title = item.title
        this.dialog.selection = 'satuan'
      }else {
        console.log('tipe');
        this.form.type = item.value
        this.dialog.selection = 'tipe'
      }

      this.dialog.selected = false
    },

    async getDetail(){
      this.$store.state.overlay.state = true

      let res = await this.$get(`pos/product/detail?id=${this.$route.query.id}`)

      if (res.status == 200) {
        let data = res.results.data
        console.log(data);
        this.form = {
          id: data.id,
          image_url: data.image_url,
          name: data.name,
          category_id: data.category_id,
          category_name: data.category_name,
          sku: data.sku,
          stock: data.stock,
          sales_minimum: data.sales_minimum,
          unit_id: data.unit_id,
          unit_title: data.unit,
          purchase_price: data.purchase_price,
          selling_price: data.sales_price,
          type: data.type,
          discount_type: data.discount_type,
          discount_value: data.discount_type == 'nominal' ? data.discount_nominal : data.discount_percent,
          desc: data.desc,
          is_selling: data.is_sale_bool == true ? 1 : 0,
          is_active: data.is_active_bool == true ? 1 : 0,
        }
        console.log(this.form);
      }

      this.$store.state.overlay.state = false
    },

    async getUnit(){
      let params = {
        sort: "",
        dir: "",
        limit: 100,
      }
      let res = await this.$get(`unit`, params)

      if (res.status == 200) {
        this.unit_list = res.results.data
      }
    },

    async getCategory(){
      let params = {
        sort: "",
        dir: "",
        limit: 100,
      }
      let res = await this.$get(`pos/category`, params)

      if (res.status == 200) {
        this.category_list = res.results.data
      }
    },

    toValidation(){
      console.log(this.form);
      if(!this.form.name){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nama Produk tidak boleh kosong"
      }else if(this.form.category_id == 0){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Kategori Produk tidak boleh kosong"
      }else if(this.form.stock == null && !this.$route.query.id){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Stok Awal tidak boleh kosong"
      }else if(this.form.sales_minimum == null){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Minimum Penjualan tidak boleh kosong"
      }else if(this.form.sales_minimum == 0){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Minimum Penjualan minimal 1"
      }else if(this.form.unit_id == 0){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Satuan Produk tidak boleh kosong"
      }else if(this.form.purchase_price == null){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Harga Beli tidak boleh kosong"
      }else if(this.form.selling_price == null){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Harga Jual tidak boleh kosong"
      }else if(this.form.discount_value == null){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Diskon Produk tidak boleh kosong"
      }else{
        this.confirmation = true
      }
    },
    async save(){
      this.confirmation = false
      this.$store.state.overlay.state = true
      let url = ""

      if(!this.$route.query.id){
        url = "pos/product/add"
      }else{
        url = "pos/product/update"
      }
      let res = await this.$post(url, this.form);

      if (res.status == 200) {
        this.$store.state.overlay.state = false
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        this.$router.push(`/master/product`)
      }

      this.$store.state.overlay.state = false

      if(res.status == 412) {
        Object.keys(res.results.data).forEach((item) => {
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = res.results.data[item]
        });
      }

      this.$store.state.snackbar.state = true
      this.$store.state.snackbar.content = res.message
      this.$store.state.overlay.state = false;
    }
  },
}
</script>